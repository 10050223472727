import { template as template_6c7763a608294bd2b168225f1a6da29e } from "@ember/template-compiler";
const FKLabel = template_6c7763a608294bd2b168225f1a6da29e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
