import { template as template_e0b0a266c4c5468494154820a6954888 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_e0b0a266c4c5468494154820a6954888(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
